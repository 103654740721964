.anonymous-enabled {
  .d-block-anonymous-enabled {
    &:not(.collapse) {
      display: block !important;
    }
    &.collapse.show {
      display: block !important;
    }
  }
  .d-flex-anonymous-enabled {
    &:not(.collapse) {
      display: flex !important;
    }
    &.collapse.show {
      display: flex !important;
    }
  }
}

.corporate-enabled {
  .d-block-corporate-enabled {
    &:not(.collapse) {
      display: block !important;
    }
    &.collapse.show {
      display: block !important;
    }
  }
  .d-flex-corporate-enabled {
    &:not(.collapse) {
      display: flex !important;
    }
    &.collapse.show {
      display: flex !important;
    }
  }
}

.comments-enabled {
  .d-block-comments-enabled {
    &:not(.collapse) {
      display: block !important;
    }
    &.collapse.show {
      display: block !important;
    }
  }
  .d-flex-comments-enabled {
    &:not(.collapse) {
      display: flex !important;
    }
    &.collapse.show {
      display: flex !important;
    }
  }
}

.joint-enabled {
  .d-block-joint-enabled {
    &:not(.collapse) {
      display: block !important;
    }
    &.collapse.show {
      display: block !important;
    }
  }
  .d-flex-joint-enabled {
    &:not(.collapse) {
      display: flex !important;
    }
    &.collapse.show {
      display: flex !important;
    }
  }
}

.matching-enabled {
  .d-block-matching-enabled {
    &:not(.collapse) {
      display: block !important;
    }
    &.collapse.show {
      display: block !important;
    }
  }
  .d-flex-matching-enabled {
    &:not(.collapse) {
      display: flex !important;
    }
    &.collapse.show {
      display: flex !important;
    }
  }
}

.other-enabled {
  .d-block-other-enabled {
    &:not(.collapse) {
      display: block !important;
    }
    &.collapse.show {
      display: block !important;
    }
  }
  .d-flex-other-enabled {
     :not(.collapse) {
      display: flex !important;
    }
    &.collapse.show {
      display: flex !important;
    }
  }
}

.pledge-enabled {
  .d-block-pledge-enabled {
    &:not(.collapse) {
      display: block !important;
    }
    &.collapse.show {
      display: block !important;
    }
  }
  .d-flex-pledge-enabled {
    &:not(.collapse) {
      display: flex !important;
    }
    &.collapse.show {
      display: flex !important;
    }
  }
}

.recurring-enabled {
  .d-block-recurring-enabled {
    &:not(.collapse) {
      display: block !important;
    }
    &.collapse.show {
      display: block !important;
    }
  }
  .d-flex-recurring-enabled {
    &:not(.collapse) {
      display: flex !important;
    }
    &.collapse.show {
      display: flex !important;
    }
  }
}

.search-enabled {
  .d-block-search-enabled {
    &:not(.collapse) {
      display: block !important;
    }
    &.collapse.show {
      display: block !important;
    }
  }
  .d-flex-search-enabled {
    &:not(.collapse) {
      display: flex !important;
    }
    &.collapse.show {
      display: flex !important;
    }
  }
}

.tribute-enabled {
  .d-block-tribute-enabled {
    &:not(.collapse) {
      display: block !important;
    }
    &.collapse.show {
      display: block !important;
    }
  }
  .d-flex-tribute-enabled {
    &:not(.collapse) {
      display: flex !important;
    }
    &.collapse.show {
      display: flex !important;
    }
  }
}

.write-in-enabled {
  .d-block-write-in-enabled {
    &:not(.collapse) {
      display: block !important;
    }
    &.collapse.show {
      display: block !important;
    }
  }
  .d-flex-write-in-enabled {
    &:not(.collapse) {
      display: flex !important;
    }
    &.collapse.show {
      display: flex !important;
    }
  }
}

.campaign-active {
  .d-block-campaign-active {
    &:not(.collapse) {
      display: block !important;
    }
    &.collapse.show {
      display: block !important;
    }
  }
  .d-flex-campaign-active {
    &:not(.collapse) {
      display: flex !important;
    }
    &.collapse.show {
      display: flex !important;
    }
  }
}