fieldset {
  + .hr {
    margin-bottom: 1rem;
  }
}

legend {
  color: $headings-color;
  float: none;
  font-style: $headings-font-style;
  font-weight: $headings-font-weight;
  margin-bottom: 1rem;
}

.legend {
  @extend legend;
}

.form-control,
.form-select {
  box-shadow: $box-shadow-inset;
  &.other-amount {
    border-bottom-right-radius: $border-radius !important;
    border-top-right-radius: $border-radius !important;
    padding-left: 0.5rem;
  }
}

.form-select {
  &:invalid:not(:focus) {
    color: $input-placeholder-color;
  }
}

option {
  font-size: 1rem;
}

[inputmode="decimal"] {
  text-align: right;
  &:focus {
    text-align: left;
  }
}

.form-submit {
  margin-top: 5.5%;
  @include media-breakpoint-up(lg) {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(#{map-get($grid-breakpoints, md)} + var(--bs-gutter-x));
  }
  button {
    width: calc(50% - 4px);
    &.d-none {
      + button {
        width: 100%;
      }
    }
  }
  @include media-breakpoint-down(md) {
    > * {
      order: 1;
    }

    > .next {
      order: 0;
    }
  }
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
