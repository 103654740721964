.confirmation {
  @extend .fs-6;
  h2 {
    @extend .legend;
    &:not(:first-child) {
      margin-top: 2rem;
    }
    br {
      display: none;
    }
  }
  label {
    display: block;
    font-weight: $font-weight-bold;
  }
  .payroll-summary {
    margin-top: 0 !important;
  }
}