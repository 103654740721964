.form-check {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  &.form-check-inline {
    display: inline-flex;
  }
  &.has-disabled-note {
    flex-wrap: wrap;
    .form-check-label {
      max-width: calc(100% - #{$form-check-input-width} - 1rem)
    }
  }
  [class*="col"]:not(.collapse)>& {
    margin-bottom: 0;
    padding-left: 0;
  }
  .input-layout [class*="col"]:not(.collapse)>& {
    padding-left: $form-check-padding-start;
  }
  .btn-check:checked+.btn {
    pointer-events: none;
  }
}

.form-check-input {
  float: none;
  margin-left: 0;
  min-width: $form-check-input-width;
  &:disabled {
    +.form-check-label {
      cursor: not-allowed;
    }
  }
  &.is-valid~.form-check-label {
    color: $form-check-label-color;
  }
}

.form-check-label {
  font-size: $input-font-size;
  margin-left: $input-btn-padding-x;
  max-width: calc(100% - #{$input-btn-padding-x});
}