.note {
  font-size: $font-size-sm;
  margin-bottom: 0;
  margin-top: .5rem;
  min-width: 100%;
  &.disabled {
    display: none;
    input:disabled~& {
      display: block;
    }
  }
  &.collapse {
    display: block;
    height: 0;
    overflow: hidden;
    transition: height .35s ease;
  }
}

.form-control,
.form-select,
.form-check {
  &:focus~.note.collapse {
    height: auto;
  }
}