.skip {
  &:focus {
    display: inline-block;
    margin: .25rem 1rem;
    outline: 1px solid;
    padding: .25rem;
  }
}

.header {
  margin-bottom: auto;
}