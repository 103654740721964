.input-layout {
  &:not(:last-child) {
    margin-bottom: 8%;
  }
  legend {
    color: $primary;
    margin-bottom: 2.25rem;
  }
  .row {
    --bs-gutter-y: 1.916rem;
  }
  .nav-tabs {
    margin-top: 1rem;
    .btn {
      @include media-breakpoint-down(sm) {
        font-size: $small-font-size;
      }
    }
  }
  .collapse.show:not(.navbar-collapse) {
    margin-bottom: 0;
  }
  .tab-content>.active {
    display: flex;
  }
  .form-select {
    // @include media-breakpoint-only(md) {
    //   font-size: calc(#{$input-font-size} * .75 + .05vw);
    //   padding-bottom: .6333rem;
    //   padding-top: .6333rem;
    // }
  }
  .btn-light.active,
  .btn-check:checked+.btn-light {
    &::after {
      @extend %button-indicator;
      border-top-color: $primary;
    }
  }
}