@use "sass:math";
// Fluid Type
@function strip-unit($value) {
  @return math.div($value, $value * 0 + 1);
}

@mixin fluid-value($min-vw, $max-vw, $min-px, $max-px, $property: font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-px);
  $u4: unit($max-px);
  @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
    & {
      #{$property}: $min-px;
      @media screen and (min-width: $min-vw) {
        #{$property}: calc(#{$min-px} + #{strip-unit($max-px - $min-px)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        #{$property}: $max-px;
      }
    }
  }
}