$dtd-offset: .4090rem;
.double-the-donation {
  margin-left: -$dtd-offset;
  margin-right: -$dtd-offset;
  min-width: calc(100% + (#{$dtd-offset} * 2));
  &.ratio::before {
    @include fluid-value($base-width, $lg-width, 416px, 307px, padding-top);
  }
}

#dd-container .lets-check,
.dtd-plugin .lets-check {
  display: none;
}

.dd-company-selected {
  color: $primary;
}