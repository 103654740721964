.form-control {
  &.readonly {
    background-color: $input-disabled-bg;
    &:first-letter {
      text-transform: uppercase;
    }
  }
}

textarea.form-control {
  font-family: $font-family-system;
  font-size: 1.25rem;
  min-height: 8rem;
}