.main.has-background-image {
  --pageBgImg: "";
  &::after {
    background-image: var(--pageBgImg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .container {
    background-color: rgba($black, .6666);
  }
  .pill-text,
  h1 {
    color: $white !important;
  }
  .btn-outline-primary {
    @extend .btn-primary;
    color: $white;
  }
  .btn-outline-dark {
    @extend .btn-light;
  }
  .btn-dark {
    @extend .btn-outline-secondary;
    background-color: transparent;
  }
  .btn-check:checked+.btn,
  .btn.active,
  .btn:focus {
    background-color: $secondary !important;
    border-color: $secondary !important;
    color: $black !important;
  }
  .form-check-label,
  .legend,
  legend {
    color: $secondary !important;
  }
  .gifts {
    color: $white;
    .btn {
      color: $white !important
    }
  }
  .gift-name {
    color: $white;
  }
  .add-gift {
    .btn {
      color: $white !important
    }
  }
  .summary {
    color: $white;
  }
  .confirmation {
    color: $white;
  }
}