.gift-info {
  fieldset {
    &:not(:last-child) {
      margin-bottom: $fieldset-bottom-margin;
    }
  }
  .grid {
    margin-bottom: calc(#{$fieldset-bottom-margin} / 2);
  }
  .hr {
    margin-bottom: 1rem;
    margin-top: calc(-#{$fieldset-bottom-margin} + 1rem);
  }
}