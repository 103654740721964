$form-floating-label-transform-sm: scale(.6666) translateY(-.5rem) translateX(.15rem);
$form-floating-label-transform-md: scale(.75) translateY(-.5rem) translateX(.15rem);
.form-floating {
  >.form-select:invalid {
    ~label {
      opacity: 1;
      transform: none;
    }
  }
  >.form-select:valid {
    ~label {
      opacity: $form-floating-label-opacity;
      transform: $form-floating-label-transform;
    }
  }
  >.form-control:focus~label,
  .form-control:not(:placeholder-shown)~label,
  >.form-select:valid~label {
    padding-left: 1rem;
    @include media-breakpoint-down(md) {
      transform: $form-floating-label-transform-sm;
    }
    // @include media-breakpoint-only(md) {
    //   transform: $form-floating-label-transform-md;
    // }
  }
  >textarea.form-control:focus,
  >textarea.form-control:not(:placeholder-shown) {
    padding-top: 2rem;
  }
  >label {
    white-space: nowrap;
    @include media-breakpoint-only(md) {
      font-size: 1.125rem;
    }
  }
}