#dd-container,
.dtd-plugin {
  .dtd-callout {
    background-color: $gray-100;
    border-color: $input-border-color;
    box-shadow: none;
  }
  .search-title {
    font-size: $font-size-lg;
  }
  .lets-check {
    margin-top: 0;
  }
  .mg-summary+.grid-x,
  .form-check+.grid-x {
    margin-left: calc(var(--bs-gutter-x)*-.5);
    margin-right: calc(var(--bs-gutter-x)*-.5);
    margin-top: calc((var(--bs-gutter-y)*.5) * -1);
    >.medium-4 {
      @include media-breakpoint-up(lg) {
        // width: 100%;
      }
    }
    >.medium-6 {
      margin-top: calc(var(--bs-gutter-y)*.5);
      padding-left: calc(var(--bs-gutter-x)*.5);
      padding-right: calc(var(--bs-gutter-x)*.5);
      >a {
        align-items: center;
        display: inline-flex;
        height: 100%;
        justify-content: center;
        width: 100%;
      }
    }
  }
  .search-another-top {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    margin-top: calc((var(--bs-gutter-y)*.5) * -1);
    >* {
      margin-top: calc(var(--bs-gutter-y)*.5);
    }
    a {
      display: inline-block;
    }
  }
  .dtd-search-box {
    margin-bottom: 2.125rem !important;
  }
  .dtd-search-input {
    font-size: $input-font-size !important;
  }
  .form-check {
    font-family: $font-family-sans-serif;
    font-size: $input-font-size;
    justify-content: center;
    margin-bottom: 1.5rem;
    min-width: 100%;
    strong {
      color: $primary;
    }
  }
  .form-check-label {
    font-size: inherit;
    line-height: 1.42;
    margin-top: .1875rem;
    max-width: calc(100% - .75rem - #{$form-check-input-width});
  }
}