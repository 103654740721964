.gifts {
  margin-bottom: 11%;
  .btn-group {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }
  .input-group {
    justify-content: flex-end
  }
  .input-group-text {
    background-color: transparent;
    border-color: transparent;
    font-size: 2.135rem;
    font-weight: $font-weight-bold;
    padding: 0 .75rem 0 0;
  }
  .form-control {
    font-size: 1.39rem;
    font-weight: $font-weight-bold;
    line-height: 1;
    max-width: 7em;
    padding-bottom: 0;
    padding-top: 0;
  }
  .btn-link {
    font-size: 1rem;
  }
  .invalid-feedback {
    text-align: right;
  }
  [class^=col] {
    .invalid-feedback {
      display: none;
    }
  }
}

.gift-name {
  font-weight: $font-weight-bold;
}

.gifts-total {
  margin-top: var(--bs-gutter-y);
}

.add-gift {
  border-bottom: 3px solid;
  margin-bottom: 1.75rem;
  margin-top: .375rem;
  padding-bottom: .5rem;
  .btn {
    text-underline-offset: 2px;
    &:before {
      content: '+';
    }
  }
}