.modal-featured-fund {
  --ffBgImg: "";
  .modal-body {
    &::before,
    &::after {
      content: "";
      display: block;
      left: 0;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
    }
    &::before {
      background-color: rgba($white, .6666);
      z-index: 1;
    }
    &::after {
      background-image: var(--ffBgImg);
      background-position: center;
      background-size: cover;
    }
    p {
      position: relative;
      z-index: 10;
    }
  }
}