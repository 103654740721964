.gift-amount {
  .input-group {
    height: 100%;
    .invalid-feedback {
      display: none;
    }
  }
  .input-group-text,
  .form-control {
    border-color: $black;
  }
  .input-group-text {
    transition: $btn-transition;
  }
  [class*=col].active {
    .input-group-text {
      background-color: $secondary;
    }
  }
  // .invalid-feedback {
  //   transform: translateY(-.5rem);
  // }
}