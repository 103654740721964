/*!
 * Bootstrap v5.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "../../node_modules/bootstrap/scss/functions";
@import "mixins";
@import "variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";
// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
// @import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
// @import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
// @import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
// @import "../../node_modules/bootstrap/scss/card";
// @import "../../node_modules/bootstrap/scss/accordion";
// @import "../../node_modules/bootstrap/scss/breadcrumb";
// @import "../../node_modules/bootstrap/scss/pagination";
// @import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
// @import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
// @import "../../node_modules/bootstrap/scss/close";
// @import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
// @import "../../node_modules/bootstrap/scss/tooltip";
// @import "../../node_modules/bootstrap/scss/popover";
// @import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/offcanvas";
// @import "../../node_modules/bootstrap/scss/placeholders";
// Helpers
@import "../../node_modules/bootstrap/scss/helpers";
// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

/**
 * FormValidation (https://formvalidation.io)
 * The best validation library for JavaScript
 * (c) 2013 - 2021 Nguyen Huu Phuoc <me@phuoc.ng>
 */

@import '../../vendors/formvalidation/src/css/core';
@import '../../vendors/formvalidation/src/css/plugins/framework';
//@import '../../vendors/formvalidation/src/css/plugins/icon';
//@import '../../vendors/formvalidation/src/css/plugins/tooltip';
@import '../../vendors/formvalidation/src/css/plugins/bootstrap5';
@import '../../vendors/formvalidation/src/css/plugins/wizard';

/**
  * Custom
  */

$fieldset-bottom-margin: 11%;
@import "reboot";
@import "typography/headings";
@import "layout/grid";
@import "layout/row";
@import "site/generic";
@import "site/header";
@import "site/footer";
@import "site/page-background";
// @import "components/banner";
@import "components/buttons";
// @import "components/button-group";
@import "components/collapse";
@import "components/list-group";
@import "components/modal";
@import "components/nav-pills";
// @import "components/navbar";
// @import "components/nav-link";
@import "components/note";
@import "forms/form";
@import "forms/floating-labels";
@import "forms/form-check";
@import "forms/form-control";
@import "forms/form-label";
@import "forms/input-group";
@import "forms/input-layout";
// @import "forms/speech";
@import "forms/validation";
@import "adf/adf";
@import "adf/adf-options";
@import "adf/confirmation";
@import "adf/double-the-donation";
// @import "adf/funds";
@import "adf/featured-fund";
@import "adf/gift-amount";
@import "adf/gift-info";
@import "adf/gift-levels";
@import "adf/gift-review";
@import "adf/gifts";
@import "adf/num-installments";
@import "adf/payroll";
@import "adf/payroll-form";
@import "adf/quick-form";
// @import "adf/search";
@import "adf/summary";
// @import "adf/write-in-fund";
@import "vendor/dtd";
// @import "utilities/helpers";