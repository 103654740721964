$form-feedback-icon-invalid-size: 1rem 1rem;
[class*="row-invalid"] {
  .invalid-feedback {
    display: block;
  }
}

.invalid-feedback {
  font-weight: $font-weight-bold;
  margin-top: .75rem;
  &:first-child {
    margin-bottom: -.5rem;
    text-align: center;
  }
  &:not(:empty)~ {
    .invalid-feedback {
      display: none;
    }
  }
}

.btn-check.is-invalid {
  +.btn {
    border-color: $danger !important;
    border-width: 3px;
    &:not(.btn-outline-dark) {
      color: $white !important;
    }
  }
  ~.invalid-feedback {
    @extend .visually-hidden;
  }
}

.form-control.is-valid,
.was-validated .form-control:valid,
.form-select.is-valid,
.was-validated .form-select:valid {
  border-color: $input-border-color;
}

.form-control,
.form-select {
  &.is-invalid {
    border-width: 3px;
  }
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-size: 1rem 1rem;
}

.form-control.other-amount.is-invalid {
  background: none;
  padding-right: 1rem;
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  background-size: 20px 18px, $form-feedback-icon-invalid-size;
}

.form-check {
  .invalid-feedback {
    min-width: 100%;
  }
}