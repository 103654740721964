.summary {
  dl {
    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }

  .maybe-empty {
    &:empty {
      display: none;
    }

    &:not(:empty) {
      &::after {
        content: " ";
      }
    }
  }
}