.quick-form {
  .d-none-quick-form {
    display: none !important;
  }
  .panel:not(:last-child) {
    margin-bottom: $fieldset-bottom-margin;
  }
  .gift-review {
    legend {
      color: $primary;
      margin-bottom: 1.5rem;
    }
  }
  .gifts {
    margin-bottom: $fieldset-bottom-margin;
  }
  .gift-recurrence {
    legend {
      color: $primary;
      margin-bottom: 1.5rem;
    }
  }
}